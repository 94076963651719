<template>
  <div
    class="d-flex justify-center align-center secondary--text"
    style="width: 100%; height: 100vh"
  >
    <div class="d-flex flex-column align-center">
      <logo class="mb-12" />
      <h1 class="text-h3 font-weight-medium mb-12">Дом на ладони</h1>
      <p class="font-weight-light" style="margin-bottom: 15vh">
        Сервис для удаленного управления жизнью объекта
      </p>
      <v-btn large :width="250" class="secondary--text accent" @click="login">
        Войти
      </v-btn>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/icons/logo.svg";
import getReturnUrl from "@/utils/getReturnUrl";

export default {
  components: { logo },
  methods: {
    login() {
      const path = getReturnUrl();
      window.open(path, "_self");
    },
  },
};
</script>

<style lang="scss" scoped></style>
